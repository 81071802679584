//// @ts-nocheck
import * as FileSaver from "file-saver";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import React, { useCallback, useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import PageLoader from "../../components/PageLoader";
import Table from "../../components/Table";
import {
    getFilteredCheckInsAPI,
    markCheckinLatePickupAPI,
    markCheckinTardyAPI,
} from "../../services/api/check_ins";
import {
    getAllClassesFromSessions,
    getSessionsForCheckinAPI,
} from "../../services/api/sessions";
import { handleException } from "../../utils/exceptions/handle_exception";

import * as XLSX from "xlsx";
import DatePicker from "../../components/DatePicker";
import PageTitle from "../../components/PageTitle";
import PlaceHolderImage from "../../components/PlaceholderImage";
import {
    CheckinModel,
    ChildModel,
    GroupModel,
    ITableData,
    SessionClassModel,
    SessionModel,
} from "../../services/api/models";
import Request from "../../services/api/request";
import { ToastNotification } from "../../services/toast_notification";
import { pushAllUniqueToArray } from "../../utils";
import { getFormattedDate } from "../../utils/DateFormat";

// components

const AttendanceList = () => {
    const [sessions, setSessions] = useState<SessionModel[]>([]);
    const [selectedSession, setSelectedSession] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [classes, setClasses] = useState<SessionClassModel[]>([]);
    const [selectedClass, setSelectedClass] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [children, setChildren] = useState<ChildModel[]>([]);
    const [selectedChild, setSelectedChild] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [fromDate, setFromDate] = useState<Date | undefined>();
    const [toDate, setToDate] = useState<Date | undefined>();

    const [changeFilter, setChangeFilter] = useState<boolean>(false);

    const [checkIns, setCheckIns] = useState<CheckinModel[]>([]);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [signinReportLoading, setSigninReportLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [manualPagination, setManualPagination] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [checkinLoading, setCheckinLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(10);
    const [fetchingRecord, setFetchingRecord] = useState(false);
    const history = useHistory();

    const getFilterObjectSchema = useCallback(
        (pageIndex?: any, pageSize?: any) => {
            return {
                group_ids: selectedGroup.map(function (sGroup) {
                    return sGroup.id;
                }),
                child_id:
                    selectedChild.length > 0 ? selectedChild[0].id : undefined,
                session_id:
                    selectedSession.length > 0
                        ? selectedSession[0].id
                        : undefined,
                session_class_ids: selectedClass.map(function (sClass) {
                    return sClass.id;
                }),
                from_date: fromDate,
                to_date: toDate,
                page_no: pageIndex,
                page_size: pageSize,
            };
        },
        [
            fromDate,
            selectedChild,
            selectedClass,
            selectedGroup,
            selectedSession,
            toDate,
        ]
    );

    const getCheckInList = React.useCallback(
        ({ pageIndex, pageSize }: any) => {
            setCheckinLoading(true);
            try {
                const filterObj = getFilterObjectSchema(
                    pageIndex ? pageIndex : 1,
                    pageSize ? pageSize : 10
                );

                getFilteredCheckInsAPI(filterObj)
                    .then((response) => {
                        if (response?.data?.results) {
                            setCheckIns(response?.data?.results);
                            setTotalCount(
                                Math.ceil(response?.data?.count / pageSize)
                            );
                            setTotalRecords(response?.data?.count);
                            setPageNumber(filterObj.page_no);
                            setPageSize(filterObj.page_size);
                            setManualPagination(true);
                        } else {
                            setCheckIns(response?.data);
                            setPageNumber(1);
                            setManualPagination(false);
                        }
                        setCheckinLoading(false);
                    })
                    .catch((error: any) => {
                        handleException(history, error);
                        // setCheckinLoading(false);
                    });
            } catch (e: any) {
                handleException(history, e);
                setCheckinLoading(false);
            }
            //   setCheckinLoading(false);
        },
        [getFilterObjectSchema, history]
    );

    useEffect(() => {
        getSessionData();
        getCheckInList({ pageIndex: 1, pageSize: 10 });
    }, []);

    useEffect(() => {
        if (changeFilter) {
            setChangeFilter(false);
            getCheckInList({ pageIndex: 1, pageSize: 10 });
        }
    }, [changeFilter, getCheckInList]);

    const getAllRecordsBasedOnFilter = async () => {
        setFetchingRecord(true);
        const filterObj = getFilterObjectSchema(1, totalRecords || 10);
        const data = await getFilteredCheckInsAPI(filterObj);
        setFetchingRecord(false);
        return data;
    };

    function exportXlsx(json: any, fileName: string) {
        const fileType =
            "application/vnd.openxmlformats-office-document.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(json);
        ws["!cols"] = [
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
        ];
        ws["!margins"] = {
            left: 1.0,
            right: 1.0,
            top: 1.0,
            bottom: 1.0,
            header: 0.5,
            footer: 0.5,
        };
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    async function exportDetails() {
        let data: any[] = [];
        const filterBasedRecords = await getAllRecordsBasedOnFilter();
        filterBasedRecords?.data?.results?.forEach(function (checkIn: any) {
            if (checkIn.checkout != null) {
                data.push({
                    Name:
                        checkIn?.child?.first_name +
                        " " +
                        checkIn.child?.last_name,
                    "Check In User":
                        checkIn?.user?.first_name +
                        " " +
                        checkIn.user?.last_name,
                    "Check Out User":
                        checkIn?.user?.first_name +
                        " " +
                        checkIn.user?.last_name,
                    "Check In": getFormattedDate(new Date(checkIn?.checkin)),
                    "Check Out": getFormattedDate(new Date(checkIn?.checkout)),
                    Hours: (
                        Math.abs(
                            new Date(checkIn?.checkin).getTime() -
                                new Date(checkIn?.checkout).getTime()
                        ) / 36e5
                    ).toFixed(2),
                });
            }
        });
        exportXlsx(data, "details");
    }

    async function exportSummary() {
        let kids = new Map<number | undefined, CheckinModel[]>();
        const filterBasedRecords = await getAllRecordsBasedOnFilter();
        filterBasedRecords?.data?.results.forEach(function (checkIn: any) {
            // if (checkIn.checkout !== null) {
            if (!kids.has(checkIn.child?.id)) {
                kids.set(checkIn.child?.id, [checkIn]);
            } else {
                kids.set(checkIn.child?.id, [
                    ...(kids.get(checkIn.child?.id) ?? []),
                    checkIn,
                ]);
            }
            // }
        });

        const getLastCheckout = (checkins: CheckinModel[]) => {
            for (let i = checkins.length - 1; i >= 0; i--) {
                if (checkins[i].checkout != null) {
                    return getFormattedDate(new Date(checkins[i].checkout!), {
                        withTime: false,
                    });
                }
            }
            return "-";
        };

        let data: any[] = [];
        kids.forEach(function (checkins) {
            let checkIn = checkins[0];
            checkins.sort(
                (first, second) =>
                    new Date(first.checkin).getTime() -
                    new Date(second.checkin).getTime()
            );
            let totalHour = 0,
                totalDays = 0;
            checkins.forEach(function (checkIn) {
                if (checkIn.checkout != null) {
                    totalDays++;
                    totalHour +=
                        Math.abs(
                            new Date(checkIn.checkin).getTime() -
                                new Date(checkIn.checkout!).getTime()
                        ) / 36e5;
                }
            });
            let totalTardy = 0,
                totalLatePickup = 0,
                totalAbsent = 0;
            checkins.forEach(function (checkIn) {
                totalTardy += checkIn.tardy ? 1 : 0;
            });

            checkins.forEach(function (checkIn) {
                totalLatePickup += checkIn.late_pickup ? 1 : 0;
            });

            checkins.forEach(function (checkIn) {
                if (
                    checkIn.absence_date != null &&
                    new Date(checkIn.absence_date).getTime() <= Date.now()
                ) {
                    totalAbsent++;
                }
            });

            data.push({
                Name:
                    checkIn.child?.first_name + " " + checkIn.child?.last_name,
                "Start Date": getFormattedDate(new Date(checkins[0].checkin), {
                    withTime: false,
                }),
                "End Date": getLastCheckout(checkins),
                "Total Days": totalDays,
                "Total Hours": totalHour.toFixed(2),
                "Total Absence": totalAbsent,
                "Total Tardy": totalTardy,
                "Total Late Pickup": totalLatePickup,

                // 'Hours': Math.trunc(Math.abs(new Date(checkIn.checkin).getTime() - new Date(checkIn.checkout!).getTime()) / 36e5)
            });
        });

        exportXlsx(data, "summary");
    }

    async function convertImgToBase64(url: string): Promise<string | null> {
        //TODO: need to change
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                if (base64data != null) {
                    resolve(base64data.toString());
                } else {
                    resolve(null);
                }
            };
        });
    }

    async function exportSignin() {
        if (signinReportLoading) return;
        setSigninReportLoading(true);
        try {
            const doc = new jsPDF();

            let data: RowInput[] = [];
            const filterBasedRecords = await getAllRecordsBasedOnFilter();
            for (const checkIn of filterBasedRecords?.data?.results) {
                if (checkIn.absence_date == null) {
                    let checkinImageData =
                        checkIn.checkin_signature != null
                            ? (await convertImgToBase64(
                                  Request.getImageUrl(checkIn.checkin_signature)
                              )) ?? -1
                            : -1;
                    let checkoutImageData =
                        checkIn.checkout_signature != null
                            ? (await convertImgToBase64(
                                  Request.getImageUrl(
                                      checkIn.checkout_signature
                                  )
                              )) ?? -1
                            : -1;

                    data.push({
                        name:
                            (checkIn.child?.first_name ?? "-") +
                            " " +
                            (checkIn.child?.last_name ?? ""),
                        date: getFormattedDate(new Date(checkIn.checkin), {
                            withTime: false,
                        }),
                        check_in: getFormattedDate(new Date(checkIn.checkin), {
                            withDate: false,
                        }),
                        check_out:
                            checkIn.checkout != null
                                ? getFormattedDate(new Date(checkIn.checkout), {
                                      withDate: false,
                                  })
                                : "-",
                        checkin_signature: checkinImageData,
                        checkout_signature: checkoutImageData,
                    });
                }
            }
            autoTable(doc, {
                // head: [['Name', 'Date', 'Check In', "Check Out", "Checkin Signature"]],
                body: data,
                columns: [
                    { header: "Name", dataKey: "name" },
                    { header: "Date", dataKey: "date" },
                    { header: "Check In", dataKey: "check_in" },
                    { header: "Checkin Signature", dataKey: "x" },
                    { header: "Check Out", dataKey: "check_out" },
                    { header: "Checkout Signature", dataKey: "w" },
                ],
                tableLineColor: [140, 140, 140],
                tableLineWidth: 0.4,
                styles: {
                    lineColor: [180, 180, 180],
                    lineWidth: 0.4,
                    minCellHeight: 30,
                    halign: "center",
                    valign: "middle",
                },
                rowPageBreak: "avoid",
                didDrawCell: (data) => {
                    if (data.section === "body" && data.column.index === 3) {
                        // @ts-ignore
                        let checkInData = data.row.raw["checkin_signature"];
                        if (checkInData != null && checkInData !== -1) {
                            doc.addImage(
                                checkInData.toString(),
                                "JPEG",
                                data.cell.x + 2.5,
                                data.cell.y + 2.5,
                                25,
                                25
                            );
                            return false;
                        }
                    }
                    if (data.section === "body" && data.column.index === 5) {
                        // @ts-ignore
                        let checkOutData = data.row.raw["checkout_signature"];
                        if (checkOutData != null && checkOutData !== -1) {
                            doc.addImage(
                                checkOutData.toString(),
                                "JPEG",
                                data.cell.x + 2.5,
                                data.cell.y + 2.5,
                                25,
                                25
                            );
                            return false;
                        }
                    }
                },
            });
            doc.save("Signin Sheet.pdf");
        } catch (e) {
            ToastNotification.error("Something wrong");
        } finally {
            setSigninReportLoading(false);
        }
    }

    // UNUSED
    // async function markAsTardy(checkin_id: number) {
    //     const response = await markCheckinTardyAPI(checkin_id);
    //     if (response.isSuccess()) {
    //         ToastNotification.success("Marked as tardy");
    //         let data = checkIns.map((checkin) => {
    //             if (checkin.id === checkin_id) {
    //                 return {
    //                     ...checkin,
    //                     tardy: true,
    //                 };
    //             } else {
    //                 return checkin;
    //             }
    //         });
    //         setCheckIns(data);
    //     }
    // }

    // async function markAsLatePickup(checkin_id: number) {
    //     const response = await markCheckinLatePickupAPI(checkin_id);
    //     if (response.isSuccess()) {
    //         ToastNotification.success("Marked as late pickup");
    //         let data = checkIns.map((checkin) => {
    //             if (checkin.id === checkin_id) {
    //                 return {
    //                     ...checkin,
    //                     late_pickup: true,
    //                 };
    //             } else {
    //                 return checkin;
    //             }
    //         });
    //         setCheckIns(data);
    //     }
    // }

    function getChildFromClass(sessionClass: SessionClassModel) {
        let children: ChildModel[] = [];
        sessionClass.groups?.forEach(function (group) {
            children = pushAllUniqueToArray(children, group.children ?? []);
        });
        return children;
    }

    async function getSessionData() {
        setPageLoading(true);
        try {
            const response = await getSessionsForCheckinAPI();
            setSessions(response.data);
            setClasses(getAllClassesFromSessions(response.data));
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "Date",
                accessor: "checkins",
                sort: true,
                maxWidth: 90,
                Cell: (data: ITableData<CheckinModel, string>) => {
                    let checkin = data.row.original.checkin;
                    let absence_date = data.row.original.absence_date;
                    return (
                        <span className="fw-semibold">
                            {getFormattedDate(
                                new Date(absence_date ?? checkin),
                                { withTime: false }
                            )}
                        </span>
                    );
                },
            },
            {
                Header: "Name",
                accessor: "child",
                sort: true,
                Cell: (data: ITableData<CheckinModel, string>) => {
                    const checkin = data.row.original;

                    function getAvatar() {
                        let avatar = checkin.child?.avatar;

                        if (avatar != null) {
                            return PlaceHolderImage({
                                src: Request.getImageUrl(avatar),
                                rounded: true,
                                size: 36,
                            });
                        }
                        return PlaceHolderImage({
                            rounded: true,
                            size: 36,
                        });
                    }
                    let id = checkin.child?.id;

                    return (
                        <Link
                            to={{
                                pathname: `/children/${id}/checkins`,
                                state: {
                                    child: data.row.original.child,
                                    group: data.row.original.group,
                                },
                            }}
                        >
                            <div className="d-flex align-items-center">
                                {getAvatar()}
                                <span className="ms-2 cursor-pointer">
                                    {checkin.child?.first_name +
                                        " " +
                                        checkin.child?.last_name}
                                </span>
                            </div>
                        </Link>
                    );
                },
            },

            {
                Header: "Status",
                accessor: "status",
                sort: true,
                Cell: (data: ITableData<CheckinModel, string>) => {
                    let absence_date = data.row.original.absence_date;
                    return absence_date != null ? (
                        <span className="fw-bold text-danger ">Absent</span>
                    ) : (
                        <span className="fw-semibold">Present</span>
                    );
                },
            },
            {
                Header: "Checkin User",
                accessor: "user",
                sort: true,
                Cell: (data: any) => {
                    return (
                        <span>
                            {data.cell.value.first_name +
                                " " +
                                data.cell.value.last_name}
                        </span>
                    );
                },
            },
            {
                Header: "Checkin",
                accessor: "checkin",
                sort: true,
                Cell: (data: ITableData<CheckinModel, string>) => {
                    let absence_date = data.row.original.absence_date;
                    if (absence_date != null) {
                        return <></>;
                    }
                    return (
                        <span>
                            {" "}
                            {getFormattedDate(new Date(data.cell.value), {
                                withDate: false,
                            })}
                        </span>
                    );
                },
            },
            {
                Header: "Checkout User",
                accessor: "checkout_user",
                sort: true,
                Cell: (data: any) => {
                    if (data.cell.value == null) {
                        return "By system";
                    }
                    return (
                        <span>
                            {data.cell.value.first_name +
                                " " +
                                data.cell.value.last_name}
                        </span>
                    );
                },
            },
            {
                Header: "Checkout",
                accessor: "checkout",
                sort: true,
                Cell: (data: ITableData<CheckinModel, string>) => {
                    let absence_date = data.row.original.absence_date;
                    if (absence_date != null) {
                        return <></>;
                    }
                    if (data.cell.value === null) {
                        return (
                            <span className="text-info">Not checked out</span>
                        );
                    }
                    return (
                        <span>
                            {getFormattedDate(new Date(data.cell.value), {
                                withDate: false,
                            })}
                        </span>
                    );
                },
            },
            // {
            //     Header: "Checkin Signature",
            //     accessor: "checkin_signature",
            //     sort: true,
            //     Cell: (data: any) => {
            //         if (data.cell.value == null) {
            //             return "-";
            //         }
            //         return PlaceHolderImage({
            //             src: Request.getImageUrl(data.cell.value),
            //             rounded: true,
            //             size: 36,
            //         });
            //
            //     },
            // },
            // {
            //     Header: "Checkout Signature",
            //     accessor: "checkout_signature",
            //     sort: true,
            //     Cell: (data: any) => {
            //         if (data.cell.value == null) {
            //             return "-";
            //         }
            //         return PlaceHolderImage({
            //             src: Request.getImageUrl(data.cell.value),
            //             rounded: true,
            //             size: 36,
            //         });
            //
            //     },
            // },
            {
                Header: "Edit",
                accessor: "id1",
                maxWidth: 60,
                Cell: (data: ITableData<CheckinModel, string>) => {
                    let id = data.row.original.id;
                    return (
                        <Link to={`/check_ins/${id}`}>
                            <i className="mdi mdi-pencil-outline"></i>
                        </Link>
                    );
                },
            },
        ],
        []
    );

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: checkIns.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <BlockUi
                    tag="div"
                    blocking={fetchingRecord}
                    message="Loading, please wait"
                >
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Report",
                                        active: true,
                                    },
                                ]}
                                title="Attendance Report"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2}>
                            <Form.Label>Select Session</Form.Label>

                            <CustomSelect
                                id="session-select"
                                multiple={false}
                                onChange={(e) => {
                                    setSelectedSession(e);
                                    if (e.length === 0) {
                                        setClasses(
                                            getAllClassesFromSessions(sessions)
                                        );
                                        return;
                                    }
                                    setSelectedClass([]);
                                    setSelectedGroup([]);
                                    setSelectedChild([]);
                                    const session = sessions.find(
                                        ({ id }) => id === e?.[0]?.id
                                    );
                                    setClasses(session?.classes ?? []);
                                    setChangeFilter(true);
                                }}
                                options={sessions.map((session) => ({
                                    id: session.id,
                                    label: session.name,
                                }))}
                                placeholder="Select a session"
                                selected={selectedSession}
                            />
                        </Col>
                        <Col lg={2}>
                            <Form.Label>Select Class</Form.Label>

                            <CustomSelect
                                id="class-select"
                                multiple={true}
                                emptyLabel={
                                    selectedSession.length > 0
                                        ? "No any class with this session"
                                        : "Please select session first"
                                }
                                onChange={(e) => {
                                    setSelectedClass(e);
                                    if (e.length === 0) return;
                                    setSelectedGroup([]);
                                    setSelectedChild([]);
                                    const c = classes.find(
                                        ({ id }) => id === e?.[0]?.id
                                    );
                                    if (c) {
                                        setChildren(getChildFromClass(c));
                                        setGroups(c.groups ?? []);
                                        setChangeFilter(true);
                                    }
                                }}
                                options={classes.map((c) => ({
                                    id: c.id,
                                    label: c.name,
                                }))}
                                placeholder="Select a class"
                                selected={selectedClass}
                            />
                        </Col>
                        <Col lg={2}>
                            <Form.Label>Select Group</Form.Label>

                            <CustomSelect
                                id="group-select"
                                multiple={true}
                                emptyLabel={
                                    selectedClass.length > 0
                                        ? "No any group with this class"
                                        : "Please select class first"
                                }
                                onChange={(e) => {
                                    setSelectedGroup(e);
                                    if (e.length === 0) return;
                                    setSelectedChild([]);
                                    const grp = groups.find(
                                        ({ id }) => id === e?.[0]?.id
                                    );
                                    setChildren(grp?.children ?? []);
                                    setChangeFilter(true);
                                }}
                                options={groups.map((group) => ({
                                    id: group.id,
                                    label: group.name,
                                }))}
                                placeholder="Select a group"
                                selected={selectedGroup}
                            />
                        </Col>
                        <Col lg={2}>
                            <Form.Label>Select Child</Form.Label>

                            <CustomSelect
                                id="child-select"
                                multiple={false}
                                emptyLabel={
                                    selectedClass.length > 0
                                        ? "No any child with this group"
                                        : "Please select group first"
                                }
                                onChange={(e) => {
                                    setSelectedChild(e);
                                    if (e.length === 0) return;
                                    setChangeFilter(true);
                                }}
                                options={children.map((child) => ({
                                    id: child.id,
                                    label: `${child.first_name} ${child.last_name}`,
                                }))}
                                placeholder="Select a child"
                                selected={selectedChild}
                            />
                        </Col>
                        <Col lg={2}>
                            <Form.Label>From</Form.Label>

                            <DatePicker
                                showAddon={true}
                                dateFormat="MMMM d, yyyy"
                                value={fromDate}
                                onChange={(date) => {
                                    setFromDate(date);
                                    setToDate(undefined);
                                }}
                            />
                        </Col>

                        <Col lg={2}>
                            <Form.Label>To</Form.Label>

                            <DatePicker
                                showAddon={true}
                                minDate={fromDate}
                                dateFormat="MMMM d, yyyy"
                                value={toDate}
                                onChange={(date) => {
                                    setToDate(date);
                                    setChangeFilter(true);
                                }}
                            />
                        </Col>
                    </Row>

                    {
                        <div>
                            <Row className="mt-3">
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            {checkIns.length === 0 && (
                                                // eslint-disable-next-line jsx-a11y/heading-has-content
                                                <h4 className="text-center"></h4>
                                            )}
                                            {!checkinLoading &&
                                                checkIns.length > 0 && (
                                                    <>
                                                        <div className="d-flex justify-content-end mb-2">
                                                            <div>
                                                                {signinReportLoading && (
                                                                    <i
                                                                        className={
                                                                            "text-primary mdi mdi-spin mdi-loading me-1"
                                                                        }
                                                                    />
                                                                )}
                                                                <u
                                                                    className="text-primary cursor-pointer"
                                                                    onClick={
                                                                        exportSignin
                                                                    }
                                                                >
                                                                    Export
                                                                    Sign-in
                                                                    Sheet
                                                                </u>

                                                                <u
                                                                    className="text-primary cursor-pointer ms-2"
                                                                    onClick={
                                                                        exportDetails
                                                                    }
                                                                >
                                                                    Export
                                                                    Detail
                                                                </u>

                                                                <u
                                                                    className="text-primary cursor-pointer ms-2"
                                                                    onClick={
                                                                        exportSummary
                                                                    }
                                                                >
                                                                    Export
                                                                    Summary
                                                                </u>
                                                            </div>
                                                        </div>
                                                        <Table
                                                            id="attedances"
                                                            columns={columns}
                                                            data={checkIns}
                                                            pageSize={pageSize}
                                                            pageNumber={
                                                                pageNumber > 0
                                                                    ? pageNumber -
                                                                      1
                                                                    : 0
                                                            }
                                                            pageCount={parseInt(
                                                                totalCount
                                                            )}
                                                            sizePerPageList={
                                                                sizePerPageList
                                                            }
                                                            isSortable={true}
                                                            pagination={true}
                                                            manualPagination={
                                                                manualPagination
                                                            }
                                                            isSearchable={true}
                                                            sortBy={[
                                                                {
                                                                    id: "checkin",
                                                                    desc: true,
                                                                },
                                                            ]}
                                                            tableClass={
                                                                "table-striped"
                                                            }
                                                            refetchData={
                                                                getCheckInList
                                                            }
                                                            actions={
                                                                <Link
                                                                    to="/check_ins/create"
                                                                    className="btn btn-primary"
                                                                >
                                                                    <i className="mdi mdi-plus-circle  me-1"></i>
                                                                    New Checkin
                                                                </Link>
                                                            }
                                                        />
                                                    </>
                                                )}
                                            <PageLoader
                                                loading={checkinLoading}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    }
                </BlockUi>
            )}
        </>
    );
};

export default AttendanceList;
