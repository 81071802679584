import Request from "./request";
import Response from "./response";
import {parseException} from "../../utils/exceptions/parse_exception";

export const getClassChildrenAPI = async (id: number) => {
    try {
        const response = await Request.getAuth(`class_children/${id}/`);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const deleteClassChildrenAPI = async (classId: number, childId: number) => {
    try {
        const response = await Request.deleteAuth(`class_children/${classId}/child/${childId}/`);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};


export const importClassChildrenAPI = async (props: {
    id: number, file: any,
    checkin_notification?: boolean,
    checkout_notification?: boolean,
    group_notification?: boolean,
    feed_notification?: boolean,
    s_checkin_notification?: boolean,
    s_checkout_notification?: boolean,
    s_group_notification?: boolean,
    s_feed_notification?: boolean,
    usersId:any
}) => {
    // let body = {
    //     dataFile: data,
    // };
    try {
        var formData = new FormData();
        var notification_setting = {
            checkin_notification: props.checkin_notification,
            checkout_notification: props.checkout_notification,
            group_notification: props.group_notification,
            feed_notification: props.feed_notification,
            s_checkin_notification: props.s_checkin_notification,
            s_checkout_notification: props.s_checkout_notification,
            s_group_notification: props.s_group_notification,
            s_feed_notification: props.s_feed_notification,
        }
        var  users_id=props.usersId;
        formData.append('users_id', users_id)
        formData.append('data_file', props.file)
        formData.append('notification_setting', JSON.stringify(notification_setting))

        const response = await Request.postAuth(`class_children/${props.id}/import`, formData, true);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};


